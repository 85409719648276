@import "../../../index.scss";

.icon_button {
  cursor: pointer;
  z-index: 2;
  min-width: 40px;
  height: 40px;
  border-radius: 30px;
  background: #ffffff;
  font-size: 0;
  border: none;
  .icon {
    font-size: 12px;
  }
}
.button_shadow {
  box-shadow: 2px 10px 20px #e0e0e0;
}
.without_shadow {
  // min-width: 50px;
  // height: 50px;
  // background-color: $background-light;
}
.icon_button:hover {
  .icon_animation {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.action_btn_label {
  width: 200px;
  color: $text;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.icon_button_top-left {
  top: 8px;
  left: 8px;
}
.icon_button_top-right {
  top: 8px;
  right: 8px;
}
.icon_button_bottom-left {
  bottom: 8px;
  left: 8px;
}
.icon_button_bottom-right {
  bottom: 8px;
  right: 8px;
}
.req-button {
  background-color: transparent;
  // width: 50px;
}
