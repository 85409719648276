.progress-container {
  display: flex;
  // gap: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  .progress-bar {
    width: 30%;
  }
  .desc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
      color: #b2b3bd;
      font-size: 14px;
    }
    .count {
      font-size: 24px;
    }
  }
  .sidebar_icon {
    font-size: larger;
    padding: 0px 5px;
    background-color: #fafafa;
    border-radius: 50%;
  }
}
