@import "../../index.scss";

.rate-cards-wrapper {
  background: $white;
  border-radius: 24px;
  padding: 20px;
  .view-mode{
    button{
      display: none !important;
    }
  }
  .rate-card-caption{
    background-color:  $primary;
    // text-align: center;
    padding: 10px 30px;
    display: inline-block;
    border-radius: 10px 10px 0 0 ;
    color: $white;
    margin-bottom: 0;
  }
  
  button {
    border: 1px solid transparent;
    padding: 10px;

    &.icon-btn {
      border-radius: 10px;
      height: 25px;
      width: 25px;
      padding: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    &.btn-danger {
      background: $red;
      color: $white;
    }

    &.btn-primary {
      background: $primary;
      color: $white;
    }
  }

  .rate-card-boxes {
    display: flex;
    border: 1px solid $primary;

    label,
    input {
      font-size: 13px;
      display: inline-block;
    }

    input,
    select {
      border: 1px solid #acabab;
      border-radius: 0;
      margin: 5px 0;
      padding: 2px 5px;
      display: inline-block;
      // min-width: 150px;
    }

  }

  .rate-divider {
    width: 1px;
    background-color: $border;
  }

  .box-item {
    padding: 10px 0;
    flex: 1;
    gap: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    .rate-card-overlay{
      background-color: white;
      opacity: 0.5;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
    }
    input {
      flex: 1;
      width: 50px;
    }

    select {
      flex: 3;
    }

  }

}