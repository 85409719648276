@import "../../../index.scss";

.button_container {
  width: 100%;
  display: flex;
  justify-content: center;
  .button {
    // height: $uiHeight;
    background-color: $primary;
    border: 0;
    border-radius: $uiBorderRadius;
    max-width: 100%;
    color: $white;
    padding: 10px 20px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
   justify-content: center;
  }
  .button:hover {
    cursor: pointer;
  }
  .button_wide {
    width: 100%;
  }
  .button-block{
    width: 100%;
  }
}
