@import "../../index.scss";

.dashboard_container {
  height: 100%;
  width: 100%;
  display: flex;
  .list_wrapper {
    // background-color: white;
    width: 60%;
    .dashboard-header {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  .progress-wrapper {
    display: flex;
    gap: 10px;
  }
  .head {
    margin-top: 20px;
  }
  .overall-info-container {
    display: flex;
    justify-content: space-between;
    .overall-left {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 48%;
    }
  }
  .second-div {
    background-color: white;
    width: 40%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-height: 100vh;
    overflow-y: scroll;
    .search_wrapper {
      width: 100%;
      padding: 10px;
    }
  }
  .chartjs-render-monitor {
    width: 400px !important;
    height: 400px !important;
  }
  .dashboard_screen_right {
    position: absolute;
    right: 0%;
    background-color: white;
    position: absolute;
    .dashboard_screen_right_header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
    }
    .close {
      cursor: pointer;
    }
    .scrollable {
      max-height: 100vh;
      background-color: white;
      padding: 10px;
      display: flex;
      flex-direction: column;
      float: right;
      overflow-y: scroll; /* Apply 'scroll' to enable vertical scrolling */
      z-index: 100;
    }
  }
}
