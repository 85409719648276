@import "../../index.scss";

.auth_screen {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 5%;
  background-color: $primary;
  background: linear-gradient(135deg, $primary 35%, rgba(0,46,124,1) 100%);
  .auth_screen_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: $uiBorderRadiusThin;
    overflow: hidden;
    background-color: #ffffff45;
    padding: 35px 0px;
    position: relative;
    .auth_screen_left_container {
      flex: 0.6;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      
      height: 100%;
      width: 100%;
      font-size: 60px;
      color: $white;
      .auth_screen_header {
        font-size: $h1;
      }
      .app_name_container {
        // padding-bottom: 100px;
        .app_name {
          font-size: 62px;
          font-style: italic;
          font-weight: 700;
          color: $white;
        }
      }
    }
    .auth_screen_right_container {
      flex: 0.6;
      overflow: auto;
      // background-color: #d9d9d9;
    }
  }
}
