@import "../../../index.scss";
.date_picker_container {
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  border-radius: 50px;
  border: $uiBorder solid $border;
  background-color: white;
  display: flex;
  color: $primary;
  .react-daterange-picker__wrapper {
    border: none;
  }
  .react-calendar__tile--hasActive {
    background-color: $primary;
  }
  .react-calendar__tile--now {
    // background-color:$primary ;
  }
  .react-calendar__tile--active {
    background-color: $primary;
  }
  .react-daterange-picker__calendar .react-calendar {
    margin-top: 10px;
    border: $uiBorder solid $primary;
    border-radius: 20px;
    padding: 10px;
  }
}
