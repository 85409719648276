@import "../../../index.scss";

.select_container {
  .select_label {
    color: $text;
    font-weight: bold;
    padding: 0.5em 0em;
    .required {
      color: red;
    }
  }
  .select_input {
    border-radius: $uiBorderRadius;
  }
}
