@import '../../index.scss';
.store_screen {
  height: 100%;
  .header_container {
    min-height: 100px;
    margin-bottom: 2em;
    .head {
      color: $text;
    }
    .header_wrapper {
      display: flex;
      align-items: center;
      .search_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        padding-right: 0;
        align-items: center;
        .add_user {
          padding: 15px;
          background-color: #6c5dd3;
          border-radius: 16px;
          color: white;
        }
      }
      .header_details_wrapper {
        display: flex;
        align-items: center;
        min-width: max-content;
        .back_image{
          cursor: pointer;
        }
        .header_image_container {
          width: 100px;
          height: 100px;
          margin: 20px;
          .header_image {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .header_details {
          .organization_name {
            font-size: 24px;
            font-weight: 600;
          }
          .organization_id {
            font-size: 16px;
            font-weight: 600;
            color: $text-light;
          }
          .organization_contact_wrapper {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
.view_modal_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.delete_modal_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
}
.delete_modal_container .react-responsive-modal-modal {
  border-radius: 20px;
}
