@import "../../index.scss";
.checkin_screen {
  height: 100%;
  .header_container {
    min-height: 100px;
    margin-bottom: 2em;
    .head {
      color: $text;
    }
    .header_wrapper {
      display: flex;
      align-items: center;
      .search_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center !important;
        gap: 15px;
        padding: 20px;
        padding-right: 0;
        .add_field_container {
          width: 250px;
        }
        .add_user {
          padding: 15px;
          background-color: #6c5dd3;
          border-radius: 16px;
          color: white;
        }
      }
    }
  }
}
