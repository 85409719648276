@import "../../index.scss";
.view_center_container {
  .view_center_wrapper {
    .view_center_body_container {
      .data-view {
        font-family: Arial, sans-serif;
        padding: 20px;
        background-color: #fff;
        border-radius: 20px;
        h4{
          padding-bottom: 20px;
        }
        .role {
          font-size: 1.5em;
          margin-bottom: 5px;
          border-bottom: 1px solid black;
        }

        .documents {
          display: flex;
          align-items: center;
          gap: 10px;

          li {
            margin-bottom: 10px;
            list-style-type: none;
            border-bottom: 1px solid $primary;
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
            strong {
              font-weight: bold;
            }

            div {
              margin-top: 5px;
              display: flex;
              gap: 5px;
              align-items: center;
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
.view_head_left_container {
  flex: 0.8;
  display: flex;
  align-items: center;
  .view_head_image_conatiner {
    .view_head_image {
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }
  }
  .view_head_title_container {
    .view_head_title_wrapper {
      margin-left: 1em;
      .view_head_title {
        font-size: 32px;
        font-weight: 600;
      }
      .view_head_sub_title {
        padding-top: 0.5em;
        color: $text-lightmode;
      }
    }
  }
}
.view_button_container {
  .view_button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .view_button {
      padding: 1em;
    }
  }
}
