.rejection_textarea {
  width: 400px;
  height: 80px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding-left: 10px;
  border-radius: 5px;
  padding-top: 5px;
  resize: none;
}

.rejection_textarea_disable {
  outline: none;
  border: 2px solid #e4e4e4;
  background-color: #e4e4e4;
}

.rejection_textarea_active {
  outline: none;
  border: 2px solid #7397c9;
  background-color: #ffffff;
}
