@import "../../../index.scss";

/* selectStyles.css */

/* Apply styles globally for react-select */
.react-select__control {
  display: flex;
  height: 56px;
  width: 190px; /* Adjust as needed */
  background-color: red;
  outline: none;
  cursor: pointer;
}

.react-select__placeholder {
  color: blue;
}

/* Add more styles as needed */
.css-1u74hrg-control {
  width: 190px;
  height: 56px;
}
