.view_dashboard_history_container {
  margin-bottom: -10px;
  .view_dashboard_history_wrapper {
    display: flex;
    align-items: center;
    margin: 2em 0em;
    .view_dashboard_back{
      text-decoration: underline;
      margin-left: 10px;
      cursor: pointer;
    }
    .view_dashboard_history {
      cursor: pointer;
      padding-right:0.5em;
      .gt_symbl {
        padding-left: 0.5em;
      }
    }
  }
}