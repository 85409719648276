@import "../../../index.scss";

.loader {
  width: 50px;
  aspect-ratio: 1;
  --_c:no-repeat radial-gradient(farthest-side,white 92%,#0000);
  background: 
    var(--_c) top,
    var(--_c) left,
    var(--_c) right,
    var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
  &.loader-primary{
    --_c:no-repeat radial-gradient(farthest-side,#2ca8ff 92%,#0000);
  }
}
@keyframes l7 {to{transform: rotate(.5turn)}}