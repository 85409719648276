@import "../../../index.scss";
.checkbox-group {
  display: block;
  &.inline{
    display: inline-block;
  }
  input{
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;
  }
  label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid $primary;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
  }
  input:checked + label:after {
    content: '\2713';
    display: inline-block;
    position: absolute;
    top : -5px;
    left: 5px;
    color: $primary;
    font-size: 23px;
  }
}
.checkbox-inline-wrapper{
  display: flex;
  gap: 20px;
}
